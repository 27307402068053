import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PaymentButton = () => {
    const [price, setPrice] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await axios.get('/app/ssl/name/');
            setPrice(response.data);
        };
        fetchProducts();
    }, []);

    const handlePayment = async (price) => {
        try {
            const response = await axios.post('/app/ssl/initiate_payment/', {
                price: price
            });
            // Handle the response, e.g., redirect to the payment URL

            if (response.data.GatewayPageURL) {
                window.location.href = response.data.GatewayPageURL; // Redirect to SSLCommerz
            }
        } catch (error) {
            console.error('Error initiating payment:', error.response?.data || error.message);
        }
    };

    return (
        <div>
            <h1>Products</h1>
            <ul>
                {price.map(product => (
                    <li key={product.id}>
                        {product.name}: {product.price}
                        <button onClick={() => handlePayment(product.price)}>Buy Now</button>
                    </li>
                ))}
            </ul>
        </div>

    );
};

export default PaymentButton;
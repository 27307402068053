import React, { useState } from 'react';
import axios from 'axios';

const SslExp = () => {
    const [name, setName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/app/ssl/model/', { name });
            alert('Data submitted successfully');
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                required
            />
            <button type="submit">Submit</button>
        </form>
    );
};

export default SslExp;